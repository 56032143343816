import type { Theme } from "baseui/theme";

export interface PlanColor {
  color: string;
  caption: string;
  backgroundColor: string;
  border: string;
  highlight?: string;
}

export const getPlanColors = (billingPlanName: string, $theme: Theme): PlanColor => {
  switch (billingPlanName) {
    case "Hobby":
      return {
        color: "#B88015",
        caption: "#AF935E",
        backgroundColor: "#FBF2DE",
        border: "#E9D2A4",
      };
    case "Starter":
    case "Team":
    case "Advanced":
    case "Pro":
      return {
        color: "#368759",
        caption: "#8AAA98",
        backgroundColor: "#E6F6ED",
        border: "#9FCAB2",
        highlight: "#134A23",
      };
    case "Agency":
    case "Premium":
      return {
        color: "#BB3582",
        caption: "#BB3582",
        backgroundColor: "#FBE6F2",
        border: "#ECAED0",
        highlight: "#BB3582",
      };
    case "Enterprise":
    case "Custom":
    case "Partner":
      return {
        color: "#2C79A5",
        caption: "#83ABC1",
        backgroundColor: "#E6F4FB",
        border: "#87BAD8",
      };
    default:
      return {
        color: $theme.colors.contentPrimary,
        caption: $theme.colors.contentPrimary,
        backgroundColor: $theme.colors.background,
        border: $theme.colors.border,
      };
  }
};
