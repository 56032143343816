import { expandBorderStyles, expandBorderTop } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React from "react";
import type { StyleObject } from "styletron-react";

export const Divider = (props: { $style?: StyleObject }) => {
  const [css, $theme] = useStyletron();

  return (
    <hr
      className={css({
        ...expandBorderStyles("none"),
        ...expandBorderTop({
          borderColor: $theme.colors.borderOpaque,
          borderWidth: "1px",
          borderStyle: "solid",
        }),
        ...props.$style,
      })}
    />
  );
};
