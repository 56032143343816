import { useEmitReactReady } from "./useEmitReactReady";

/**
 * Emit an event the first time we're rendered for anyone listening
 * Used by Cypress to wait for the react app to hydrate before running tests
 */

export const EmitReactReady = () => {
  useEmitReactReady();
  return null;
};
