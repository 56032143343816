import { Row } from "@gadgetinc/widgets";
import React from "react";
import AuthAppBg from "../../../../public/assets/bg-new-app-gradient.svg";

export const AuthAppGradientBackground = () => {
  return (
    <Row
      $style={{
        backgroundImage: `url(${AuthAppBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "100vh",
      }}
    />
  );
};
