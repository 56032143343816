import { Row, expandBorderRadii, paddingHorizontal, paddingVertical } from "@gadgetinc/widgets";
import { UpgradeIcon } from "@gadgetinc/widgets/src/icons/UpgradeIcon";
import { useStyletron } from "baseui";
import { Tab, Tabs } from "baseui/tabs-motion";
import type { Theme } from "baseui/theme";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useMemo } from "react";
import { useLocation } from "wouter";
import { GadgetAuthConfigContext } from "./AuthLayout";

export const useTeamTabs = () => {
  const [location, setLocation] = useLocation();
  const [locationBase, billingTab] = location.split("#");

  const gadgetAuthConfig = useContext(GadgetAuthConfigContext);

  const url = useMemo(() => {
    return new URL(locationBase, gadgetAuthConfig.servicesRootURL);
  }, [gadgetAuthConfig, locationBase]);

  const activeKey = useMemo(() => {
    if (url.pathname === "/auth/team/billing" && billingTab === "plans") {
      return "4";
    } else if (url.pathname === "/auth/team/billing" && billingTab == "usage") {
      return "2";
    } else if (url.pathname === "/auth/team/billing") {
      return "3";
    } else if (url.pathname === "/auth/team/members") {
      return "1";
    }

    return "0";
  }, [url, billingTab]);

  const switchTab = useCallback(
    (tab: string) => {
      if (tab === "1" || tab === "members") {
        setLocation(url.search ? `/auth/team/members${url.search}` : "/auth/team/members");
      } else if (tab === "2" || tab === "usage") {
        setLocation(url.search ? `/auth/team/billing${url.search}#usage` : "/auth/team/billing#usage");
      } else if (tab === "3" || tab === "billing") {
        setLocation(url.search ? `/auth/team/billing${url.search}` : "/auth/team/billing");
      } else if (tab === "4" || tab === "plans") {
        setLocation(url.search ? `/auth/team/billing${url.search}#plans` : "/auth/team/billing#plans");
      } else {
        setLocation(url.search ? `/auth/team${url.search}` : "/auth/team");
      }
    },
    [url, setLocation]
  );

  return { activeKey, switchTab };
};

export const TeamTabs = observer((props: { isOnFreePlan?: boolean }) => {
  const { isOnFreePlan } = props;
  const { activeKey, switchTab } = useTeamTabs();
  const [_, $theme] = useStyletron();

  const TabOverrides = {
    Tab: {
      style: ({ $theme, $isActive }: { $theme: Theme; $isActive?: boolean }) => ({
        ...paddingVertical($theme.sizing.scale200),
        ...paddingHorizontal($theme.sizing.scale300),
        ...$theme.typography.body,
        fontWeight: $isActive ? 700 : 500,
        color: $isActive ? $theme.colors.primary : $theme.colors.primary600,
        // baseui Tab uses background instead of backgroundColor; this is so that we don't get a bunch of warnings in the console
        background: $isActive ? $theme.colors.primary100 : $theme.colors.background,
        ...expandBorderRadii($theme.borders.radius200),
        ":hover": {
          background: $theme.colors.primary100,
        },
      }),
    },
    TabPanel: {
      style: () => ({
        display: "none",
      }),
    },
  };

  const upgradeMarkup = isOnFreePlan ? (
    <Row $gap={$theme.sizing.scale500} $style={{ color: $theme.colors.electricBlue500 }}>
      <UpgradeIcon />
      Upgrade
    </Row>
  ) : (
    "Plans"
  );

  return (
    <Tabs
      overrides={{
        TabBorder: {
          style: {
            display: "none",
          },
        },
        TabList: {
          style: {
            gap: "24px",
          },
        },
        TabHighlight: {
          style: {
            display: "none",
          },
        },
      }}
      activeKey={activeKey}
      onChange={({ activeKey }) => {
        switchTab(activeKey.toString());
      }}
    >
      <Tab title="Applications" overrides={TabOverrides}></Tab>
      <Tab title="Members" overrides={TabOverrides}></Tab>
      <Tab title="Usage" overrides={TabOverrides}></Tab>
      <Tab title="Billing" overrides={TabOverrides}></Tab>
      <Tab title={upgradeMarkup} overrides={TabOverrides}></Tab>
    </Tabs>
  );
});
