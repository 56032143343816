import { Row, paddingHorizontal } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React from "react";
import { SubHeading } from "../../SubHeading";
import { getPlanColors } from "./utils";

export const BillingPlanTag = (props: { billingPlanName: string; includePlanQualifier?: boolean }) => {
  const { billingPlanName, includePlanQualifier } = props;
  const [_, $theme] = useStyletron();

  const { color: planColor, backgroundColor: planBackgroundColor } = getPlanColors(billingPlanName, $theme);

  const displayName = includePlanQualifier ? `${billingPlanName} Plan` : billingPlanName;

  return (
    <Row
      $style={{
        backgroundColor: planBackgroundColor,
        ...paddingHorizontal($theme.sizing.scale100),
      }}
    >
      <SubHeading $style={{ color: planColor, textTransform: "uppercase", letterSpacing: "1.5px" }}>{displayName}</SubHeading>
    </Row>
  );
};
