import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import { omit } from "lodash";
import React from "react";

export const AccordionExpandIcon = (props: React.ComponentProps<typeof StyledSvg> & { direction?: "up" | "down" | "left" | "right" }) => {
  const [_css, $theme] = useStyletron();

  let rotation = 0;
  if (props.direction == "down") {
    rotation = 90;
  } else if (props.direction == "left") {
    rotation = 180;
  } else if (props.direction == "up") {
    rotation = -90;
  }

  return (
    <StyledSvg viewBox="0 0 30 30" {...omit(props, "direction")}>
      <path
        d="M20.625 15.0001C20.625 15.1974 20.5494 15.3948 20.3991 15.5451L12.6908 23.2534C12.4704 23.4738 12.1389 23.5401 11.8506 23.4207C11.5623 23.3019 11.375 23.0198 11.375 22.7084V7.29179C11.375 6.98037 11.5623 6.69825 11.8506 6.57954C12.1389 6.46006 12.4704 6.52635 12.6908 6.74681L20.3991 14.4551C20.5494 14.6054 20.625 14.8028 20.625 15.0001Z"
        fill={props.color || $theme.colors.contentSecondary}
        transform={`rotate(${rotation} 15 15)`}
      />
    </StyledSvg>
  );
};
