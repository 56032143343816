import { createTheme } from "baseui";
import { merge } from "lodash";
import { defaultSemanticOverrides, editOverrides, monoFontFamily, primitivesLight, type GadgetStyletronTheme } from "./EditTheme";
import { generateTypography } from "./utils";

export const proseFamily = `Helvetica, -apple-system, BlinkMacSystemFont, sans-serif`;

export const AuthLightTheme: GadgetStyletronTheme = createTheme(primitivesLight, merge({}, defaultSemanticOverrides, editOverrides, {}));

export const AuthMarketingTheme: GadgetStyletronTheme = createTheme(
  primitivesLight,
  merge({}, defaultSemanticOverrides, editOverrides, {
    typography: generateTypography(
      {
        primaryFamily: proseFamily,
        monoFamily: monoFontFamily,
      },
      {
        title: {
          fontFamily: proseFamily,
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "30px",
          textAlign: "left",
        },
        navTitle: {
          fontFamily: proseFamily,
          fontSize: "12px",
          fontWeight: 700,
          lineHeight: "16px",
          textAlign: "left",
        },
        heading: {
          fontFamily: proseFamily,
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "22px",
          textAlign: "left",
        },
        subHeading: {
          fontFamily: proseFamily,
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "20px",
          textAlign: "left",
        },
        body: {
          fontFamily: proseFamily,
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0px",
          textAlign: "left",
        },
        caption: {
          fontFamily: proseFamily,
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0px",
          textAlign: "left",
        },
        contentLink: {},
      }
    ),
  })
);
