import { StyledSvg } from "baseui/icon";
import React from "react";

export const PlusIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.39988 14.7491C7.47828 14.8286 7.57169 14.8918 7.6747 14.9349C7.7777 14.978 7.88825 15.0002 7.99991 15.0002C8.11157 15.0002 8.22211 14.978 8.32512 14.9349C8.42812 14.8918 8.52154 14.8286 8.59993 14.7491C8.67833 14.6696 8.74014 14.5753 8.78177 14.4717C8.8234 14.3681 8.84403 14.2573 8.84245 14.1456L8.84239 14.1422L8.84243 8.84259H14.1463C14.2577 8.84405 14.3682 8.82347 14.4716 8.78193C14.5752 8.7403 14.6695 8.67849 14.749 8.6001C14.8285 8.5217 14.8916 8.42829 14.9347 8.32528C14.9778 8.22227 15 8.11173 15 8.00007C15 7.88841 14.9778 7.77786 14.9347 7.67486C14.8916 7.57185 14.8285 7.47844 14.749 7.40004C14.6695 7.32165 14.5752 7.25984 14.4716 7.21821C14.368 7.17657 14.2571 7.15595 14.1455 7.15753L14.142 7.15759L8.84243 7.15755V1.8536C8.84392 1.74115 8.82294 1.62951 8.78059 1.52527C8.73815 1.42078 8.67516 1.32587 8.59536 1.24617C8.51556 1.16648 8.42057 1.10361 8.31603 1.0613C8.21148 1.01899 8.09951 0.998096 7.98675 0.999859C7.76348 1.00335 7.55073 1.09531 7.39522 1.25555C7.23981 1.41569 7.15427 1.63093 7.15736 1.85404L7.15736 1.85447L7.15739 1.85793V7.15755H1.85347C1.74216 7.15609 1.63162 7.17667 1.52826 7.21821C1.42465 7.25984 1.33035 7.32165 1.25084 7.40004C1.17133 7.47844 1.1082 7.57185 1.0651 7.67486C1.02201 7.77787 0.999817 7.88841 0.999817 8.00007C0.999817 8.11173 1.02201 8.22227 1.0651 8.32528C1.1082 8.42829 1.17133 8.5217 1.25084 8.6001C1.33035 8.67849 1.42465 8.7403 1.52826 8.78193C1.63187 8.82357 1.74271 8.84419 1.85436 8.84261L1.85863 8.84255L7.15739 8.84259V14.1465C7.15593 14.2578 7.17651 14.3684 7.21805 14.4717C7.25968 14.5753 7.32149 14.6696 7.39988 14.7491Z"
    />
  </StyledSvg>
);
