import {
  Banner,
  BannerKind,
  Column,
  Row,
  expandBorderBottom,
  expandBorderRadii,
  expandBorderRight,
  marginVertical,
  paddingHorizontal,
} from "@gadgetinc/widgets";
import { AuthLightTheme } from "@gadgetinc/widgets/src/AuthTheme";
import { GadgetLogoMark } from "@gadgetinc/widgets/src/icons/GadgetLogo";
import * as Sentry from "@sentry/react";
import { BaseProvider, useStyletron } from "baseui";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import type { LayoutProps } from "fastify-renderer/client/react";
import type { ReactNode } from "react";
import React, { Suspense } from "react";
import { GlobalHotKeys } from "react-hotkeys-ce";
import type { GadgetFlags } from "state-trees/src/GadgetFlags";
import type { InitialBillingPlanProps, TeamProps } from "state-trees/src/Team";
import type { FlashMessage } from "state-trees/src/utils";
import { Client } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { Provider as FetchProvider } from "use-http";
import { Link, useLocation } from "wouter";
import { EmitReactReady } from "../../lib/EmitReactReady";
import { usePageTracking } from "../../lib/eventTracker";
import { FlagsProvider } from "../../lib/flags";
import "../../lib/hotreload.css";
import { useDisplayFlashMessage } from "../../lib/utils";
import { Grid } from "../Grid";
import { DelayedCenteredSpin } from "../chrome/CenteredSpin";
import { AuthAppGradientBackground } from "./AuthAppBackground/AuthAppBackground";
import { AuthBox } from "./AuthBox";
import { LoadHubSpotChat } from "./LoadHubSpotChat";
import { TeamDropdown } from "./TeamDropdown";
import { TeamTabs } from "./TeamTabs";
import { UserDropdown } from "./UserDropdown";
import "./auth-global.css";

export const XXLargeWidth = 1260;
export const XLargeWidth = 1080;
export const LargeWidth = 960;
export const MediumWidth = 640;
export const SmallWidth = 560;

// Breakpoints used in auth pages
export const Breakpoint = {
  XXLarge: `@media screen and (max-width: ${XXLargeWidth}px)`,
  XLarge: `@media screen and (max-width: ${XLargeWidth}px)`,
  Large: `@media screen and (max-width: ${LargeWidth}px)`,
  Medium: `@media screen and (max-width: ${MediumWidth}px)`,
  Small: `@media screen and (max-width: ${SmallWidth}px)`,
} as const;

export const inputOverrides = {} as const;

const getHydrations = () => document.getElementsByClassName("_styletron_hydrate_") as HTMLCollectionOf<HTMLStyleElement>;

export const styletronEngine = import.meta.env.SSR
  ? ({} as any)
  : new Client({
      hydrate: getHydrations(),
      prefix: "g-",
    });

// Ensure sentry only runs on the client
if (!import.meta.env.SSR && import.meta.env.PROD) {
  Sentry.init({ dsn: "https://09658668253b494eade7c8b60a96b819@o250689.ingest.sentry.io/5352808" });
}

export interface GadgetAuthConfig {
  servicesRootURL: string;
  currentUser: {
    id: number;
    email: string;
    name: string | null;
    isStaff: boolean;
    createdDate: string;
  };
  team?: {
    id: string;
    name: string;
    isOnFreePlan: boolean;
    isOnStaffPlan: boolean;
    billingPlanName: string;
  };
  teamOwner?: boolean;
}
export const GadgetAuthConfigContext = React.createContext<GadgetAuthConfig>(null as any);

const AuthProviders = (props: { children: ReactNode; flags: GadgetFlags; config: GadgetAuthConfig }) => {
  const stack = (
    <FlagsProvider flags={props.flags}>
      <BaseProvider
        theme={AuthLightTheme}
        zIndex={1000}
        overrides={{
          AppContainer: {
            style: ({ $theme }) => ({
              color: $theme.colors.contentPrimary,
              backgroundColor: $theme.colors.backgroundPrimary,
              height: "auto",
              maxHeight: "none",
              maxWidth: "none",
              minHeight: "100vh",
              minWidth: "100%",
              display: "flex",
              flexDirection: "column",
              ...AuthLightTheme.typography.body,
            }),
          },
        }}
      >
        <ToasterContainer
          placement={PLACEMENT.bottomRight}
          overrides={{
            Root: {
              style: {
                marginBottom: "32px",
                marginRight: "100px",
              },
            },
          }}
        >
          <FetchProvider url="/">
            <GadgetAuthConfigContext.Provider value={props.config}>{props.children}</GadgetAuthConfigContext.Provider>
          </FetchProvider>
        </ToasterContainer>
      </BaseProvider>
    </FlagsProvider>
  );

  if (import.meta.env.SSR) {
    return stack;
  } else {
    return <StyletronProvider value={styletronEngine}>{stack}</StyletronProvider>;
  }
};

type AuthNavBarProps = {
  hasWideContent?: boolean | string;
  userEmail?: string;
} & (
  | {
      team?: undefined;
      otherTeams?: undefined;
      initialPlan?: undefined;
    }
  | {
      team: TeamProps;
      otherTeams: TeamProps[];
      initialPlan: InitialBillingPlanProps;
    }
);

const AuthNavBar = (props: AuthNavBarProps) => {
  const { team, otherTeams, initialPlan, userEmail } = props;
  const [css, $theme] = useStyletron();

  const [location] = useLocation();
  const [url] = location.split("?");

  const withoutTeamTabs = WithoutTeamsTabsRoutes.includes(url);

  return (
    <Column $style={{ ...expandBorderBottom({ ...$theme.borders.border100, borderColor: $theme.colors.primary200 }) }}>
      <Grid
        $gap={$theme.sizing.scale800}
        $style={{
          gridTemplateColumns: "1fr max-content 1fr",
          [Breakpoint.XXLarge]: {
            gridTemplateColumns: "1fr 1fr",
          },
          alignSelf: "center",
          height: "67px",
          backgroundColor: $theme.colors.background,
          ...paddingHorizontal($theme.sizing.scale900),
          minWidth: "100%",
        }}
      >
        <Row $gap={$theme.sizing.scale600}>
          <Link href={props.userEmail ? "/auth/team" : url}>
            <GadgetLogoMark
              className={css({
                width: $theme.sizing.scale1000,
                pointerEvents: "all",
                ":hover": {
                  cursor: "pointer",
                },
              })}
            />
          </Link>
          {team && !withoutTeamTabs && (
            <>
              <div
                className={css({
                  width: "0px",
                  height: $theme.sizing.scale900,
                  ...expandBorderRight({ ...$theme.borders.border100, borderColor: $theme.colors.primary200 }),
                })}
              ></div>
              <TeamDropdown team={team} otherTeams={otherTeams} initialPlan={initialPlan} />
            </>
          )}
        </Row>
        {team && (
          <Row $style={{ [Breakpoint.XXLarge]: { display: "none" } }}>
            {!withoutTeamTabs && <TeamTabs isOnFreePlan={team.isOnFreePlan} />}
          </Row>
        )}
        {userEmail && <UserDropdown $style={{ justifyContent: "flex-end" }} userEmail={userEmail} />}
      </Grid>
      {team && !withoutTeamTabs && (
        <div
          className={css({
            display: "none",
            paddingLeft: "96px",
            paddingBottom: $theme.sizing.scale600,
            [Breakpoint.XXLarge]: { display: "flex", paddingLeft: $theme.sizing.scale900 },
          })}
        >
          <TeamTabs isOnFreePlan={team.isOnFreePlan} />
        </div>
      )}
    </Column>
  );
};

export const SuspenseFallback = (props: { spinnerDelay?: number }) => {
  const [_, $theme] = useStyletron();

  return (
    <AuthBox
      pageTitle="Loading..."
      heading={false}
      isTopAligned
      maxWidth="850px"
      minWidth="850px"
      backgroundColor={$theme.colors.primary50}
    >
      <Column
        $style={{
          width: "100%",
        }}
      >
        <DelayedCenteredSpin delay={props.spinnerDelay} />
      </Column>
    </AuthBox>
  );
};

export type AuthChromeProps = {
  children: ReactNode;
  hasPreviewLayout?: PreviewLayoutConfig | undefined;
  userEmail?: string;
  hasWideContent?: boolean | string;
  flashMessage?: FlashMessage | null;
  masqueradeTeamId?: string;
} & (
  | {
      team?: undefined;
      otherTeams?: undefined;
      initialPlan?: undefined;
    }
  | {
      team: TeamProps;
      otherTeams: TeamProps[];
      initialPlan: InitialBillingPlanProps;
    }
);

const AuthChrome = (props: AuthChromeProps) => {
  const [_, $theme] = useStyletron();
  useDisplayFlashMessage(props.flashMessage);
  const [_location, setLocation] = useLocation();

  return (
    <>
      {props.hasPreviewLayout ? (
        <Row
          $style={{
            position: "fixed",
            left: 0,
            top: 0,
            [Breakpoint.Small]: {
              display: "none",
            },
          }}
          onClick={() => {
            if (props.hasPreviewLayout?.redirectOnClick) {
              setLocation(props.hasPreviewLayout.redirectOnClick);
            }
          }}
        >
          <AuthAppGradientBackground />
        </Row>
      ) : (
        <AuthNavBar {...props} />
      )}

      <Suspense fallback={<SuspenseFallback spinnerDelay={100} />}>
        <EmitReactReady />
        {props.hasPreviewLayout ? (
          <Row $style={{ alignItems: "center", justifyContent: "center", minHeight: "100vh" }}>
            <Row
              $style={{
                ...marginVertical("40px"),
                ...expandBorderRadii($theme.borders.radius200),
                maxHeight: `calc(100vh - 80px)`,
                overflow: "visible",
                zIndex: 1,
                position: "relative",
                [Breakpoint.Small]: {
                  ...marginVertical("0"),
                  backgroundColor: "transparent",
                  maxHeight: "none",
                  overflow: "visible",
                  width: "100%",
                },
              }}
            >
              {props.children}
            </Row>
          </Row>
        ) : (
          <>
            {props.masqueradeTeamId && (
              <Banner kind={BannerKind.warning} title="Be careful">
                You are masquerading as team id {props.masqueradeTeamId}. Remember: with great power comes great responsibility.
              </Banner>
            )}
            {props.children}
          </>
        )}
      </Suspense>
    </>
  );
};

type PreviewLayoutConfig = { redirectOnClick: string | false };

const PreviewLayoutRoutes: Record<string, PreviewLayoutConfig> = {
  "/auth/new-app": { redirectOnClick: "/auth/team" },
  "/auth/new-app/summon": { redirectOnClick: false },
};
const WithoutTeamsTabsRoutes = ["/auth/team/new"];

export const AuthLayout = (props: LayoutProps) => {
  usePageTracking();
  const destination = props.navigationDestination.split("?")[0];
  const hasPreviewLayout = PreviewLayoutRoutes[destination] as PreviewLayoutConfig | undefined;
  const userEmail = props.bootProps.isEmailVerified === false ? undefined : props.bootProps.currentUser?.email;

  return (
    <AuthProviders
      flags={props.bootProps.GadgetFlags}
      config={{
        ...props.bootProps.config,
        currentUser: props.bootProps.currentUser,
        team: props.bootProps.team,
        teamOwner: props.bootProps.teamOwner,
      }}
    >
      <GlobalHotKeys />
      <LoadHubSpotChat path="/auth/api/identify-support-conversation" />
      <AuthChrome {...props.bootProps} hasPreviewLayout={hasPreviewLayout} userEmail={userEmail}>
        {props.children}
      </AuthChrome>
    </AuthProviders>
  );
};

export default AuthLayout;
